@font-face {
    font-family: Poppins-regular;
    src: url('../fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('../fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: Poppins-Bold;
    src: url('../fonts/Poppins-Bold.ttf');
}

body {
    font-family: Poppins-regular;
    font-size: 13px;
    color: #000;
    line-height: 1.5;
    background-color: #FAFBFE !important;
    font-weight: 500;
}
p{
    font-family: Poppins-regular; 
    font-size: 15px;
}
a{
    font-family: Poppins-Medium;
    font-size: 12px;
    color: #673ab7;
    text-decoration: none;
}
h1,h2,h3,h4,h5,h6{
    font-family: Poppins-Medium;
    font-weight: 500;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bg-info{
    background: #673ab7 !important;
}

.readonly-input {
    background-color:#0000000f;
    color: #000;
  }
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
::placeholder{
    font-size: 12px !important;
}
.form-select{
    font-size: 14px !important;
}
/*Button Css*/
.btn-primary{
    min-width: 120px;
    background: #673ab7;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding: 6px 10px;
    -webkit-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease;
    border-color: #673ab7;
}
.btn-primary:hover {
    background: #fe820e;
    border-color: #fe820e;
    color: #fff;
}
.btn-secondary{
    min-width: 120px;
    background: #463f3f ;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding: 6px 10px;
    -webkit-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease;
    border-color: #463f3f ;
}
.btn-secondary:hover {
    background: #673ab7;
    border-color: #673ab7;
    color: #fff;
}
.form-control:focus{
    border-color: #673ab7;
    box-shadow: none;
}
.spin-sm .spinner-border{
    width: 1rem;
    height: 1rem;
}
/* sidebar Css Start*/
.sidebar{
    width: 250px;
    z-index: 1001;
    background: #ffffff;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    padding: 80px 10px;
    border-right: 1px solid #E8EBED;
    overflow-y: scroll;
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
    transition: all 0.2s ease;
}
.sidebar-menu .accordion-button .link-text{
    padding-left: 12px;
}
.sidebar-menu .accordion-button:hover{
    color: #FFE!important;
    border-radius: 8px !important;
    background: #673ab7;
    box-shadow: none !important;
    padding: 0.625rem 20px;
}
.sidebar-menu .accordion-button:hover .fa-circle-chevron-down{
    color: #FFE!important;
}
.sidebar-links{
    list-style: none;
    padding-left: 0;
}
.main-content {
    margin-left: 250px;
    overflow: hidden;
    transition: all 0.2s ease;
}
.page-content {
    padding: 80px 0px 80px;
}
.sidebar ul li a {
    margin-top: 30px;
    display: block;
    padding: 8px 18px;
    color: #000;
    position: relative;
    font-size: 14px;
    transition: all .4s;
    text-decoration: none;
    margin: 0px 0px 8px; 
    background-color: transparent;
}
.sub-menu .active{
    color: #673ab7!important;
    border-radius: 8px !important;
    font-weight: 600;
    box-shadow: none !important;
}
.sub-menu .active:hover{
    color: #673ab7 !important;
    border-radius: 8px !important;
    font-weight: 600;
    box-shadow: none !important;
}
.sidebar ul li a:hover{
    color: #FFE;
    border-radius: 8px;
    background: #673ab7;
    box-shadow: none !important;
    transition: 0.5s;
}
.sidebar .sub-menu li a:hover{
    color: #673ab7 !important;
    background-color: #fff !important;
    font-weight: 600;
    transition: 0.5s;
}

.sidebar-menu{
    margin: 0px 15px 8px;
}


.sidebar-menu .accordion-button{
    background-color: transparent;
    color: #000;
    border-radius: 0px !important;
    box-shadow: none !important;
    padding: 0.625rem 20px;
    margin-bottom: 16px;
}
.drop-down-menu .accordion-item{
    background-color: transparent;
    border: none;
}
.sidebar-menu .accordion-button:not(.collapsed){
    color: #FFE!important;
    border-radius: 8px !important;
    background: #673ab7;
    box-shadow: none !important;
    padding: 0.625rem 20px;
}

.unactive-link button{
    margin: 0px 0px 8px 0px;
    color: #000!important;
    box-shadow: none !important;
    padding: 0.625rem 20px;
}
.sidebar i{
    margin-right: 8px;
}
.home-icon{
    margin-right: 2px !important;
}
.active-link button{
    color: #FFE!important;
    margin: 0px 0px 8px 0px;
    border-radius: 8px !important;
    background: #673ab7 !important;
    box-shadow: none !important;
    padding: 0.625rem 20px;
}
.active-link button .fa-circle-chevron-down{
    color: #fff !important;
    rotate: 180deg;
    transition: 0.5s;
}
.unactive-link button:hover{
    color: #FFE !important;
    border-radius: 8px;
    background: #673ab7;
    box-shadow: none !important;
    transition: 0.5s;
}
.unactive-link button:hover .fa-circle-chevron-down{
    color: #FFE !important;
    rotate: 360deg;
    transition: 0.5s;
}
.sidebar .accordion-body{
    padding-top: 5px;
    padding-bottom: 0px;
}
.sidebar_hide{
    width: 0px;
    padding: 80px 0px;
}
.accordion-button::after{
    display: none;
 }
 .sidebar-menu .accordion-button:not(.collapsed) .fa-circle-chevron-down{
    rotate: 180deg;
    color: #fff;
 }
.link-text{
    font-size: 13px;
}
.toggle_button i{
    background: #673ab7;
    color: #fff;
    padding: 10px 14px;
    font-size: 18px;
    border-radius: 6px;
}
.sub-menu{
    margin-left: 6px;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
}
.sub-menu li a{
    margin-bottom: 8px !important;
    margin-left: 7px !important;
    padding-left: 0 !important;
}
.sub-menu.sub-menu2{
    max-height: 200px; /* Adjust as needed */
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}
.sub-menu.sub-menu-about{
    height: auto;    
    overflow: auto !important;
    max-height: 200px; /* Adjust as needed */
}


.fa-circle-chevron-down{
    margin-left: auto;
    color: #28c76f;
}
.sub-menu .active{
    color: #6744b1 !important;
    font-weight: 800;
    background: transparent !important;
}
.active{
    color: #FFE!important;
    /* margin: 0px 8px 8px 8px !important; */
    border-radius: 8px !important;
    background: #673ab7 !important;
    box-shadow: none !important;
    padding: 0.625rem 20px;
}
.h-0{
    height: 0;
    z-index: -0;
    z-index: 1001;
    background: #ffffff;
    bottom: 0;
    margin-top: 0;
    position: relative;
    top: 0;
    padding-top: 0;
    border-right: 1px solid #E8EBED;
    overflow-y: hidden;
    transition: all 0.2s ease;
}
.h-1{
    height: 100px;
    z-index: -0;
    z-index: 1001;
    background: #ffffff;
    bottom: 0;
    margin-top: 0;
    position: relative;
    top: 0;
    padding-top: 0;
    border-right: 1px solid #E8EBED;
    transition: all 0.2s ease;
}
/* sidebar Css End */

/* Navbar Css Start*/
.drop-down-menu ul li a {
    margin-top: 0px;
    display: block;
    color: #000;
    position: relative;
    font-size: 13px;
    transition: all .4s;
    text-decoration: none;
    padding-left: 0;
}
.drop-down-menu ul{
    padding-left: 35px;
}
.header-section{
    background: #fff;
    border-bottom: 1px solid #DEE2E6;
    height: 60px;
    z-index: 999;
    position: fixed !important;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999999999999999999;
    padding: 2px 0 0px;
}
.header-section .navbar-brand{
    float: left;
    height: 40px;
    position: relative;
    text-align: center;
    width: 250px;
    z-index: 1;
    padding: 0px 20px;
    transition: all 0.2s ease;
    border-right: 1px solid #DEE2E6;
    border-bottom: 0px solid #DEE2E6;
}

.navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}


.header-profile .dropdown-menu {
    position: absolute;
    left: -60%;
    top: 100%;
}
.header-profile button{
    background: transparent;
    border: none;
    color: #000;
    padding-top: 0;
}
.header-profile button:hover{
    background: transparent;
    border: none;
    color: #000;
}
.navbar-ful{
    margin-left: 0px;
}
.navbar-brand-hide{
    width: 50px !important;
}
.logo-size{
    width: auto;
    height: 107%;
    margin-top: -5px;
}
.btn:focus-visible{
    color: #000;
    background-color: transparent;
    border-color: transparent;
    outline: 0;
    box-shadow: none !important;
}
.btn:first-child:active{
    color: #000;
    background-color: transparent;
    border-color: transparent;
}
.btn.show{
    color: #000;
    background-color: transparent;
    border-color: transparent;
}
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  .username{
    text-transform: capitalize;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #673ab7; 
  }
/* Navbar Css End */

/* Dashboard Page Css Start*/
.dash-count {
    background: #673ab7;
    color: #fff !important;
    min-height: 98px;
    width: 100%;
    border-radius: 6px;
    margin: 0 0 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dash-count .dash-imgs {
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}
.dash-count .dash-imgs i {
    font-size: 36px;
}
.dash-count h5 {
    font-size: 14px;
}
.dash-counts h4{
    font-size: 20px;
}
.dash-count:hover .dash-imgs {
    transform: scale(1.25);
    -webkit-transform: scale(1.25);
    -ms-transform: scale(1.25);
}
.dash-count.das1 {
    background: #28c76f;
}
.dash-count.das2 {
    background: #463f3f;
}
.dash-count .dash-imgs svg {
    width: 36px;
    height: auto;
}
.bill_box {
    text-align: left !important;
    background: #fff !important;
    box-shadow: 0 1px 0px 1px #e5d9d9 !important;
    border-bottom: 1px solid #e5e5e5;
    padding: 18px 20px 12px;
}
.view_invoice_i{
    color: #444 !important;
    margin: 13px 8px 0;
    font-size: 18px !important;
}
.invoice_i {
    font-size: 18px !important;
}
.c_name {
    font-size: 18px !important;
}
.text-green {
    color: #28c76f !important;
}
.amount {
    font-size: 20px !important;
    font-weight: 600;
    margin-left: 5px;
}
.delet_invoice_i{
    color: red !important;
    margin: 13px 8px 0;
    font-size: 18px !important;
}
/* Dashboard Page Css End*/

/* Fixed Button Page Css End*/
.fixed {
    position: fixed;
    bottom: 3%;
    width: 100%;
    right: 1%;
    text-align: end;
    z-index: 999999999;
    height: 55px;
}
.quot_btn {
    padding: 8px 50px;
    border-radius: 40px !important;;
    color: #fff;
    background: #463f3f !important;;
    border-color: #463f3f !important;
    margin-right: 5px;
}
.quot_btn:hover {
    background: #673ab7 !important;;
    border-color: #673ab7 !important;;
    color: #fff;
}
.plus_btn {
    padding: 15px 19px;
    border-radius: 40px;
    color: #fff;
    background: #28c76f;
    border-color: #28c76f;
    margin-right: 5px;
    font-size: 13px;
}
.plus_btn:hover {
    background: #463f3f;
    border-color: #463f3f;
}
.bill_btn {
    padding: 8px 50px;
    border-radius: 40px;
    color: #fff;
    background: #673ab7;
    border-color: #673ab7;
    margin-right: 5px;
}
.bill_btn:hover {
    background: #463f3f;
    border-color: #463f3f;
    color: #fff;
}
.notification-exp{
    font-size: 15px !important;
    color: red;
}

/* Fixed Button Page Css End*/

/* Mobile Navigation Page Css Start*/
.mb{
    display: none !important;
}
.fixed-bottom-c {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    display: flex;
    justify-content: space-between;
    background: #fff;
    align-items: center;
    box-shadow: 0px 0px 8px 1px #d1cccc;
    height: 70px;
}
.nav-item2 {
    padding: 10px 8px 8px;
}
.nav-link {
    color: #463f3f;
}
.nav-item2 .active{
    padding: 6px 10px 6px !important;
}
/* Mobile Navigation Page Css end*/

/*Search List Start*/
.calendar-icon {
    color: #555;
    position: absolute;
    z-index: 9;
    right: 10px;
    font-size: 14px;
  }
  .date-picker-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  .form-control{
    font-size: 14px;
  }
/*Search List End*/

/*Quotation Bill Recipts Form Start*/
.w-10{
    width: 3%;
    border-width: 3px;
    color: #28c76f;
    opacity: 1;
    margin: 10px 0 4px;
}
.react-datepicker-wrapper{
    width: 100%;
}
.quotaion-create {
    margin-bottom: 70px;
    border: 1px solid #e8ebed;
}
/*Quotation Bill Recipts Form End*/

/*Subscription Plans start*/
.sub-plan{
    color: #673ab7;
}
/*Subscription Plans End*/

/*update Company Plans Start*/
.updatecompanysection {
    margin-bottom: 0 !important;
    border-bottom: 0;
}
.updatecompanysection button{
    padding-left: 15px;
    padding-right: 15px;
    padding: 0.625rem 20px;
}
.updatecompany{
    padding: 0 !important;
    background: white !important;
}
.updatecompanysection .active{
    border-radius: 0 !important;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    background: #28c76f !important;
}
.profile_name{
    font-weight: 400;
}
.profile_name_data{
    font-size: 15px;
}
.profile_name_data_terms{
    font-size: 15px;
    line-height: 25px;
}

.sigCanvas{
    touch-action: none;
    box-shadow: 0px 0px 12px 0px #d1d1d1;
    border: 1px solid #673ab7;
    margin-bottom: 15px;
    border-radius: 10px;
    height: auto !important;
}
.sigCanvas2{
    touch-action: none;
    border: 1px solid #673ab7;
    margin-bottom: 15px;
    border-radius: 10px;
    height: auto;
}
.sign-view{
    width: 300px;
    height: auto;
}
.stemp{
    color: blue;
    font-weight: 400;
    text-transform: capitalize;
}


/*update Company Plans End*/

/*Login Start*/
.login-wrapper {
    width: 100%;
    overflow: hidden;
    height: 100vh;
    display: contents;
}
.login-wrapper .login-content {
    width: 40%;
    height: 100vh;
    padding: 30px;
    overflow: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.login-wrapper .login-content .login-userset {
    width: 100%;
}
.login-wrapper .login-content .login-logo {
    display: block;
    margin-bottom: 25px;
    max-width: 150px;
}
.login-wrapper .login-content .login-userheading {
    margin: 0 0 30px;
}
.login-wrapper .login-content .login-userheading h3 {
    font-size: 24px;
    font-family: Poppins-Medium;
    margin: 0 0 15px;
    color: #000;
}
.login-wrapper .login-content .signinform {
    margin-bottom: 25px;
}
.login-wrapper .login-content .signinform h4 {
    font-size: 14px;
    font-weight: 400;
    color: #212b36;
}
.login-wrapper .login-content .signinform h4 a {
    color: #212b36;
    font-weight: 600;
}
.hover-a:after {
    content: "";
    height: 2px;
    width: 100%;
    background: #673ab7;
    transform: scale(0);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
    position: absolute;
    bottom: -3px;
    left: 0;
}
.login-wrapper .login-img {
    width: 60%;
}
.login-wrapper .login-img img {
    width: 100%;
    height: 100vh;
}
.login-wrapper .login-content .pass-group .fas {
    font-size: 13px;
    color: #939ea8;
}

.login-wrapper .login-content .toggle-password {
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #ccc;
    font-size: 18px;
    border-radius: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    position: absolute;
    top: 72%;
    right: 0px;
}
.login-wrapper .login-content .toggle-user {
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #ccc;
    font-size: 18px;
    border-radius: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    position: absolute;
    top: 72%;
    right: 0px;
}
.login-wrapper .login-content .pass-group {
    position: relative;
    font-size: 14px;
}
.login-wrapper .login-content .form-login input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(145, 158, 171, .32);
    border-radius: 5px;
    padding: 0 15px;
    -webkit-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease;
}
.login-wrapper .login-content .form-login .btn-login {
    width: 100%;
    font-weight: 700;
    background: #673ab7;
    border-color: #673ab7;
    padding: 12px 0px 35px;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
    margin-top: 5px;
    color: #fff;
}
.login-wrapper .login-content .form-login {
    margin-bottom: 15px;
}
.more-btn.p-0.active{
    background: white !important;
    color: #000 !important;
    padding: 0 !important;
}
/*Login End*/


/* Recipts bill quotation List Start*/
.not_data i{
    font-size: 30px;
    color: #673ab7;
}

.not_data p{
    font-size: 16px;
    color: #000;
    margin-top: 5px;
}
.btn-close2{
    background-color: #fff;
    opacity: 1;
}
.swal2-title{
    font-size: 24px;
}
.swal2-container{
    z-index: 999999999999;
}
.pending_amount{
    font-size: 11px;
    display: block;
    font-weight: 600;
    background: #ff000094;
    padding: 2px 0;
    text-align: center;
    color: #fff !important;
    border-radius: 6px;
}
.pending_amount i{
    font-size: 10px !important;
    color: #fff !important;
}
.readonly-input {
    cursor: no-drop;
    background-color: #f0f0f0; /* Optional: Add a background color to visually indicate read-only state */
  }
/* Recipts bill quotation List end*/

/* upload_img Company Logo Start*/
.upload_img{
    cursor: pointer;
    margin-bottom: 8px;
    position: relative;
    width: 100%;
}
.upload_icon{
    position: absolute;
    top: 70%;
    right: 43%;
    color: #fff;
    font-size: 16px;
    background: #28c76f;
    border-radius: 70%;
    padding: 10px;
}
.img-display-after{
    width: 150px;
    height: 150px;
    border-radius: 100%;
}
.img-display-before{
    border-radius: 100%;
    width: 150px;
    height: 150px;
}
.box-decoration, .image-upload-container {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.image-upload-container {
    padding: 10px 0px 20px;
}

.box-decoration {
    border: 2px dashed #ccc;
    border-radius: 5px;
    flex-direction: column;
    padding: 23px 0;
}

.box-decoration, .image-upload-container {
    align-items: center;
    display: flex;
    justify-content: center;
}

.image-upload-label {
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
    background-color: #673ab7 !important;
    border-color: #673ab7 !important;
    opacity: 0.40 !important;
}
.model-index{
    z-index: 99999999999999;
}
/* upload_img Company Logo End*/
.react-datepicker-popper{
    z-index: 999999999 !important;
}