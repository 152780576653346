/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
    .sidebar{
        width: 0px;
        padding: 80px 0px;
    }
    .dd{
        width: 0px !important;
        padding: 80px 0px !important;
    }
    .sidebar_hide {
        width: 100%;
        padding: 80px 10px;
        z-index: 99999999999;
    }
    .main-content{
        margin-left: 0;
    }
    .page-content {
        padding: 75px 0px 15px;
        margin-bottom: 135px;
    }
    .sidebar_hide .link-text{
        display: inline-block;
    }
    .sidebar ul li a{
        padding: 0.625rem 18px;
        margin-bottom: 5px;
    }
    .dash-count h5 {
        font-size: 12px;
    }
    .dash-count{
        padding: 12px;
    }
    .fixed {
        position: fixed;
        bottom: 12%;
        width: 100%;
        text-align: center;
        right: 0;
        left: 0;
        z-index: 999999999;
    }
    .quot_btn {
        padding: 13px 37px;
        border-radius: 40px !important;
        color: #fff;
        background: #463f3f !important;
        border-color: #463f3f !important;
        margin-right: 3px;
        font-size: 14px;
    }
    .bill_btn {
        padding: 13px 27px;
        border-radius: 40px !important;
        color: #fff;
        background: #673ab7 !important;
        border-color: #673ab7 !important;
        margin-right: 0px;
        font-size: 14px;
    }
    .mb{
        display: flex !important;
        z-index: 999999999;
    }
    h5{
        font-size: 15px;
    }
    .c_name {
        font-size: 14px !important;
    }
    .amount {
        font-size: 18px !important;
    }
    .invoice_i {
        font-size: 16px !important;
    }
    .view_invoice_i {
        font-size: 16px !important;
    }
    .delet_invoice_i {
        font-size: 16px !important;
    }
    .btn-primary {
        font-size: 13px;
        min-width: 108px;
        padding: 7px 10px 7px 7px;
    }
    .quotaion-create {
        margin-bottom: 0px;
    }
    .login-wrapper .login-content {
        width: 100%;
        padding: 15px;
    }
    .login-wrapper .login-content .login-userset {
        margin-top: 30px;
        background: #fff;
        box-shadow: 0 1px 0px 1px #e5d9d9 !important;
        padding: 50px 15px;
        border-radius: 12px;
    }
    .login-wrapper .login-img {
        display: none;
    }
    .updatecompanysection .nav-link{
        padding: 9px !important;
    }
    .d-mob-block{
        display: block !important;
    }
    .md-block{
        display: block !important;
    }
    .invoice_design .bill_btn{
        padding: 8px 30px !important;
        height: 65px !important;
        font-size: 22px !important;
    }
    .invoice_design .quot_btn{
        padding: 8px 30px !important;
        height: 65px !important;
        font-size: 22px !important;
    }
    .invoice_design .plus_btn{
        padding: 8px 30px !important;
        height: 65px !important;
        font-size: 22px !important;
    }
    .logo-size {
        height: 90%;
        margin-top: -5px;
        width: auto;
    }
}
